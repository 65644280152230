import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import Translations from "./Translations";
import { Icon } from "@iconify/react";
import {getActivePaymentMethod } from "../../features/product/productApi";

function OfferNotificationBar() {
  const [free_shipping, setFreeShipping] = useState(0);

  useEffect(() => {
    (async () => {
      const paymentData = await getActivePaymentMethod();
      if (paymentData?.status) {
        for (const payment of paymentData?.data) {
          if (payment?.key === "free_shipping") {
            setFreeShipping(payment?.value);
          }
        }
      }
    })();
  }, []);

  return (
    <Box className="top_bar"
      sx={{
        textAlign: "center",
        background: "#0f3f83",
        padding: "5px 10px ",
        color: "#ffffff",
        display: "flex",
        alignItems: "center",
        minHeight:'43px',
        justifyContent: "center",
      }}
    >
      <Typography sx={{display:'inline-flex'}}>
        <Icon icon="material-symbols:local-shipping" fontSize={24}></Icon>{'  '}&nbsp;&nbsp;
        <b>
          <Translations text="Free Shipping" />
        </b>{'  '}&nbsp;
        <Translations text="for orders via" />{'  '}&nbsp;
        <b>{free_shipping} €</b>{'  '}&nbsp;&nbsp;
        <Icon icon="material-symbols:local-shipping" fontSize={24}></Icon>
      </Typography>
    </Box>
  );
}

export default OfferNotificationBar;
