import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Box,
  Button,
  TextField,
  Divider,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { cartTotal, cartTotalPrescriptionFee } from "../../utils/util";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../features/product/productSlice";
import { applyCouponCodeApi, getActivePaymentMethod } from "../../features/product/productApi";
import { NavLink, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { update } from "@intercom/messenger-js-sdk";

const CartDrawer = ({ showDrawer, toggleDrawer }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState("");
  const [totalPrescriptionFee, setTotalPrescriptionFee] = useState("");
  const [showVoucher, setShowVoucher] = useState(false);
  const [voucherCode, setVoucherCode] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [shippingFee, setShippingFee] = useState(0);
  const { userData } = useSelector((state) => state?.user || "");

  const getCartData = async () => {
    const cartData = window.localStorage.getItem("cartData");
    const voucherData = window.localStorage.getItem("voucherData");
    if (cartData) {
      const cartD = JSON.parse(cartData);
      if (cartD?.length > 0) {
        setCartItems(cartD);
        let total = cartTotal(cartD);
        setTotalAmount(total?.toFixed(2));
        let totalPF = cartTotalPrescriptionFee(cartD);
        setTotalPrescriptionFee(totalPF?.toFixed(2));
      }
    }
    if (voucherData) {
      const voucher = JSON.parse(voucherData);
      setVoucherCode(voucher?.code);
      await applyCouponCode(voucher?.code);
    }
  };

  useEffect(() => {
    getCartData();
  }, []);

  useEffect(() => {
    window.addEventListener("cartUpdate", () => {
      getCartData();
    });
  }, []);

  useEffect(() => {
    (async () => {
      const paymentData = await getActivePaymentMethod();
      let minShippingAmount = 0;
      if (paymentData?.status) {
        for (const payment of paymentData?.data) {
          if (payment?.key === "shipping_fee") {
            setShippingFee(payment?.value);
          }
          if (payment?.key === "free_shipping") {
            minShippingAmount = payment?.value;
          }
        }
        const amount = parseFloat(totalAmount) - parseFloat(discountAmount) || 0;
        if (amount >= parseFloat(minShippingAmount)) {
          setShippingFee(0);
        }
      }
    })();
  }, [totalAmount, discountAmount]);

  const handleDelete = (item) => {
    const cartData = [...cartItems];
    const updatedCart = cartData?.filter(
      (cItem) =>
        cItem?.productId != item?.productId ||
        cItem?.selectedQuantity != item?.selectedQuantity ||
        cItem?.selectedStrength != item?.selectedStrength ||
        cItem?.subCategoryId != item?.subCategoryId ||
        cItem?.categoryId != item?.categoryId
    );
    window.localStorage.setItem("cartData", JSON.stringify(updatedCart));
    if(updatedCart?.length === 0){
      window.localStorage.removeItem('voucherData')
    }
    setCartItems(updatedCart);
    let total = cartTotal(updatedCart);
    setTotalAmount(total?.toFixed(2));
    window.dispatchEvent(new Event("cartUpdate"));
    let totalPF = cartTotalPrescriptionFee(updatedCart);
    setTotalPrescriptionFee(totalPF?.toFixed(2));
    //addItemToCart(JSON.stringify(updatedCart))
  };
  const handleQuantity = (item, type) => {
    const cartData = [...cartItems];
    const updatedCart = cartData?.map((cItem) => {
      if (
        cItem?.productId == item?.productId &&
        cItem?.selectedQuantity == item?.selectedQuantity &&
        cItem?.selectedStrength == item?.selectedStrength &&
        cItem?.subCategoryId == item?.subCategoryId &&
        cItem?.categoryId == item?.categoryId
      ) {
        cItem.quantity =
          type == "plus"
            ? cItem.quantity + 1
            : cItem.quantity - 1 < 1
            ? 1
            : cItem.quantity - 1;
      }
      return cItem;
    });
    window.localStorage.setItem("cartData", JSON.stringify(updatedCart));
    if(updatedCart?.length === 0){
      window.localStorage.removeItem('voucherData')
    }
    setCartItems(updatedCart);
    let total = cartTotal(updatedCart);
    setTotalAmount(total?.toFixed(2));
    window.dispatchEvent(new Event("cartUpdate"));
    //addItemToCart(JSON.stringify(updatedCart))
  };

  const toggleVoucher = (show) => {
    setShowVoucher(!show);
  };

  const applyCouponCode = async (voucherCode) => {
    if (voucherCode !== "") {
      const couponData = await applyCouponCodeApi({ coupon: voucherCode, cartTotal: (totalAmount-totalPrescriptionFee-0).toFixed(2)  });
      if (couponData?.status) {
        window.localStorage.setItem(
          "voucherData",
          JSON.stringify(couponData?.data)
        );
        if (couponData?.data?.discount_type === "fixed") {
          setDiscountAmount(couponData?.data?.discount_amount);
        } else {
          const discount =
            (totalAmount * couponData?.data?.discount_amount) / 100;
          setDiscountAmount(discount?.toFixed(2));
        }
      } else {
        setDiscountAmount(0);
        setVoucherCode("");
        toast.error(couponData?.message);
        window.localStorage.removeItem("voucherData");
      }
    } else {
      toast.error(`${t("The code field is required")}`);
    }
  };

  const addItemToCart = async (getCartData) => {
    if (userData?.data?.id) {
      try {
        await dispatch(addToCart(getCartData)).unwrap();
      } catch (error) {}
    }
  };

  const handleCheckoutClick = async () => {
    await addItemToCart(JSON.stringify(cartItems));
    navigate("/checkout");
  };

  console.log(totalAmount,shippingFee,discountAmount,parseInt(shippingFee), shippingFee>0 , (parseFloat(totalAmount) + (shippingFee>0 ? parseFloat(shippingFee) : 0) - discountAmount - 0), '(totalAmount + (shippingFee>0 ? parseFloat(shippingFee) : 0) - discountAmount - 0).toFixed(2)')

  return (
    <Drawer
      anchor="right"
      open={showDrawer}
      onClose={() => toggleDrawer(false)}
    >
      <Box className="cartDrawer">
        <Box className="cartDrawer_close">
          <Typography>{t("Your shopping cart")}</Typography>
          <IconButton onClick={() => toggleDrawer(false)}>
            <Icon icon="ic:sharp-close" />
          </IconButton>
        </Box>
        <Divider />
        {cartItems?.length > 0 ? (
          <Box
            sx={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Typography sx={{ fontSize: "14px", color: "#0f3f83" }}>
                {t("Congratulations! You have unlocked FREE SHIPPING!")}
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  height: "10px",
                  borderRadius: "15px",
                  background: "#fbaa1a",
                }}
              ></Box>
              <Divider sx={{ m: "20px 0px", width: "100%" }} />
            </Box>

            {cartItems?.length > 0 && (
              <Box
                sx={{
                  overflow: "hidden auto",
                  flexGrow: 1,
                  padding: "10px 0",
                  maxHeight: "73%",
                }}
              >
                {cartItems?.map((item, index) => (
                  <>
                    <Box
                      key={`ccartItem${index}`}
                      sx={{ display: "flex", flexFlow: "wrap", gap: "20px" }}
                    >
                      <Box className="cartDrawer_img">
                        <div className="cartDrawer_imgbx">
                          <NavLink
                            to={item?.productPath}
                            alt={item?.productName}
                          >
                            <img
                              src={item?.image}
                              alt={item?.productName}
                              width="105"
                            />
                          </NavLink>
                          <IconButton onClick={(e) => handleDelete(item)}>
                            <Icon icon="fontisto:close" />
                          </IconButton>
                        </div>
                      </Box>
                      <Box sx={{ width: "58.33%" }}>
                        <Box className="cartDrawer_namebx">
                          <Typography className="prdnameTitle">
                            <NavLink
                              to={item?.productPath}
                              alt={item?.productName}
                              style={{color:'#0f3f83'}}
                            >
                              {item?.productName}- x{item?.quantity}
                            </NavLink>
                          </Typography>
                          <Typography variant="" className="prdPrice">
                             {item?.productPrice} €
                          </Typography>
                        </Box>
                        <Typography className="prdnameSubTitle">
                          {t("Strength")}: {item?.selectedStrength}
                        </Typography>
                        <Typography className="prdnameSubTitle">
                          {t("Quantity in package")}: {item?.selectedQuantity}
                        </Typography>
                        <div className="cartDrawer_qty">
                          <Icon
                            style={{
                              borderRight: "1px solid rgb(235, 233, 235)",
                            }}
                            fontSize={28}
                            icon="ic:baseline-minus"
                            onClick={(e) => handleQuantity(item, "minus")}
                          />
                          <TextField
                            className="pd_qty_fld"
                            readOnly
                            value={item?.quantity}
                          />
                          <Icon
                            style={{
                              borderLeft: "1px solid rgb(235, 233, 235)",
                            }}
                            fontSize={28}
                            icon="ic:baseline-plus"
                            onClick={() => handleQuantity(item, "plus")}
                          />
                        </div>
                      </Box>
                    </Box>
                    {index + 1 < cartItems?.length && (
                      <Divider sx={{ m: "20px 0" }} />
                    )}
                  </>
                ))}
              </Box>
            )}
            <Divider sx={{ m: "20px 0 0" }} />
            <Box className="cart_coupon_code_row">
              <Box className="cart_coupon_code">
                <Typography sx={{cursor:'pointer'}} onClick={(e) => toggleVoucher(showVoucher)}>{t("Do you have a voucher code?")}</Typography>
                <IconButton onClick={(e) => toggleVoucher(showVoucher)}>
                  <Icon icon={showVoucher ? "fe:arrow-up" : "fe:arrow-down"} />
                </IconButton>
              </Box>
              {showVoucher && (
                <Box className="cart_coupon_code_form">
                  <TextField
                    sx={{ height: "3rem", flexGrow: "1" }}
                    name="voucherCode"
                    value={voucherCode}
                    onChange={(e) => setVoucherCode(e?.target?.value)}
                  />
                  <Button
                    variant="contained"
                    onClick={() => applyCouponCode(voucherCode)}
                  >
                    {t("Input")}
                  </Button>
                </Box>
              )}
            </Box>
            <Divider sx={{ mb: "20px" }} />
            <Box className="cart_shipment">
              <Typography>
                <span>{t("Shipment")}:</span>{" "}
                <span>
                  <b>{shippingFee > 0 ? shippingFee+' €' : t("Free Shipping")}</b>
                </span>
              </Typography>
              {discountAmount > 0 && (
                <Typography>
                  <span>{t("Discount")}: </span>
                  <span>
                    <b>{discountAmount > 0 ? `- ${discountAmount}` : 0} €</b>
                  </span>
                </Typography>
              )}
              <Typography>
                <span>{t("Prescription fee")}:</span>{" "}
                <span>
                  <b>{`${totalPrescriptionFee} €`}</b>
                </span>
              </Typography>
            </Box>
            <Divider sx={{ m: "20px 0px" }} />
            <Button
              className="button1"
              fullWidth
              variant="contained"
              onClick={handleCheckoutClick}
            >
              {t("Order now {{price}}", {
                price: `${(parseFloat(totalAmount) + (shippingFee>0 ? parseFloat(shippingFee) : 0) - discountAmount - 0).toFixed(2)} €`,
              })}
              <Icon
                style={{ marginLeft: "7px" }}
                icon="fa-solid:shopping-basket"
                fontSize={20}
              />
            </Button>
          </Box>
        ) : (
          <Box className="cart_empty">
            <img src="/images/empty-cart.png" alt="Empty Cart" />
            <Typography variant="h4">{t("cartEmptyTitle")}</Typography>
            <Typography>{t("cartEmptyDescription")}</Typography>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default CartDrawer;
