import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  categoryList,
  faqList,
  productSearch,
} from "../../features/common/commonSlice";

import CartDrawer from "../Cart/CartDrawer";
import Menu from "./Menu";
import Translations from "./Translations";
import { Intercom } from "@intercom/messenger-js-sdk";
Intercom({
  app_id: "ln1b341r",
  custom_launcher_selector: "#customIntercomButton",
  // user_id: user.id,
  // name: user.name,
  // email: user.email,
  // created_at: user.createdAt,
});

function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [searchValue, setSearchValue] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [count, setCount] = useState(0);
  const { searchProducts, status } = useSelector((state) => state?.common);
  const dispatch = useDispatch();
  const [cartQuantity, setCartQuantity] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const fetchCartQuantity = async () => {
    const cartData = window.localStorage.getItem("cartData");
    if (cartData) {
      const cartD = JSON.parse(cartData);
      if (cartD?.length > 0) {
        // Sum the price property of each object using reduce()
        let total = cartD?.reduce(
          (accumulator, current) => accumulator + current.quantity,
          0
        );
        setCartQuantity(total);
      }
    }
  };

  useEffect(() => {
    fetchCartQuantity();
  }, []);

  useEffect(() => {
    window.addEventListener("cartUpdate", () => {
      fetchCartQuantity();
    });
  }, []);

  useEffect(() => {
    (async () => {
      try {
        dispatch(categoryList()).unwrap();
        dispatch(faqList()).unwrap();
      } catch (error) {}
    })();
  }, [dispatch]);

  useEffect(() => {
    if (searchProducts?.data?.data?.length > 0) {
      setCount(searchProducts?.data?.total);
    } else {
      setCount(searchProducts?.data?.total);
    }
  }, [searchProducts]);

  const toggleMenu = (newOpen) => {
    setShowMenu(newOpen);
  };

  const toggleDrawer = (newOpen) => {
    setShowDrawer(newOpen);
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value !== "") {
      const query = `search=${e.target.value}&page=${1}&perPage=${12}`;
      dispatch(productSearch(query)).unwrap();
      setShowOptions(true);
    } else {
      setShowOptions(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?query=${searchValue}&page=1&perPage=12`);
  };

  return (
    <nav
      className="navbar"
      style={{ background: "#ddf0ff", padding: "10px 0px" }}
    >
      <Container className="container">
        <Grid container alignItems={"center"}>
          <Grid className="logo" item xs={6} sm={3} md={3} lg={2}>
            <Box>
              <a href="/">
                <img
                  src="/images/apotheke-premium-logo.webp"
                  alt=""
                  width={200} height={80}
                />
              </a>
            </Box>
          </Grid>
          <Grid className="search_bar" item xs={12} sm={5} md={6}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              sx={{ width: "100%" }}
              onSubmit={handleSubmit}
            >
              <FormControl fullWidth sx={{ display: "block" }}>
                <TextField
                  value={searchValue}
                  onChange={(e) => handleSearch(e)}
                  placeholder={`${t("Search for products or symptoms...")}`}
                  aria-describedby="validation-basic-last-name"
                  sx={{ width: "100%", background: "#ffffff" }}
                />
                <Button
                  sx={{ background: "#fbab1b !important" }}
                  onClick={handleSubmit}
                >
                  <Icon icon="carbon:search" color="#ffffff" fontSize={24} />
                </Button>
              </FormControl>
            </Box>

            {/* Search Options Popup */}
            {showOptions && (
              <Box className="result_box">
                <Box className="result_count">
                  {status === "succeeded"
                    ? t("{{show}} results found", { show: count })
                    : t("Loading")}
                  <IconButton onClick={()=>setShowOptions(false)}><Icon icon="ic:sharp-close" /></IconButton>
                </Box>
                <ul>
                  {searchProducts?.data?.data?.length > 0 &&
                    searchProducts?.data?.data?.map((product, index) => (
                      <>
                      {index < 5 && 
                      <li key={`productsearch${index}`}>
                        <NavLink
                          to={`/shop/${product?.category?.parent?.slug}/${product?.category?.slug}/${product?.slug}`}
                        >
                          <div className="result_prdimg">
                            <Box
                              component="img"
                              src={product?.images?.[0]?.file_path}
                              alt={product?.name}
                            />
                          </div>
                          <Box className="result_prdcontent">
                            <Typography variant="h2">
                              {product?.name}
                            </Typography>
                            <Typography sx={{ padding: "2px 0 4px" }}>
                              {product?.category?.parent?.name} |{" "}
                              {product?.brand?.name}
                            </Typography>
                            <Typography sx={{ fontWeight: 700 }}>
                              {t("From")} {product?.product_price} €
                            </Typography>
                          </Box>
                          <Box className="result_viewbtn">
                            <Button className="button1">{t("View")}</Button>
                          </Box>
                        </NavLink>
                      </li>}
                      </>
                    ))}
                  <li>
                    <NavLink
                      to={`/search?query=${searchValue}&page=1&perPage=12`}
                    >
                      <Box className="result_prdcontent">
                        <Typography
                          variant="h2"
                          sx={{
                            textAlign: "center",
                            textDecoration: "underline",
                          }}
                        >
                          Show All
                        </Typography>
                      </Box>
                    </NavLink>
                  </li>
                </ul>
              </Box>
            )}
          </Grid>
          <Grid
            className="menu_toggle"
            item
            xs={12}
            md={2}
            display={{ xs: "none", sm: "none", md: "none", lg: "block" }}
          >
            <Button
              sx={{
                background: "#0f3f83 !important",
                color: "#ffffff",
                borderRadius: "30px",
              }}
              onClick={() => toggleMenu(true)}
            >
              <Translations text="Menu" />
              <Icon icon="mingcute:menu-fill" fontSize={30} color="#ffffff" />
            </Button>
          </Grid>
          <Grid
            className="header_end"
            item
            xs={6}
            sm={4}
            md={3}
            lg={2}
            sx={{ color: "#0f3f83" }}
          >
            <Link
              to={user?.first_name !== undefined ? "/my-account/dashboard" : "/my-account"}
              className="account_icon"
            >
              <Icon icon="mingcute:user-4-fill" fontSize={32} color="#0f3f83" />
              <Translations text="Account" />
            </Link>
            <div className="account_icon" onClick={() => toggleDrawer(true)}>
              <Icon
                icon="fa-solid:shopping-basket"
                fontSize={32}
                color="#0f3f83"
              />
              
                <span className="cart_count">{cartQuantity}</span>
              
              <Translations text="Shopping Cart" />
            </div>
            <div
              className="account_icon menu_icon"
              onClick={() => toggleMenu(true)}
            >
              <Icon icon="mingcute:menu-fill" fontSize={32} color="#0f3f83" />
              <Translations text="Menu" />
            </div>
          </Grid>
        </Grid>
      </Container>
      <Menu open={showMenu} handleClose={toggleMenu} />

      {/* Drawer for Cart */}
      <CartDrawer
        showDrawer={showDrawer}
        toggleDrawer={toggleDrawer}
      ></CartDrawer>
    </nav>
  );
}

export default Header;
