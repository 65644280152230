import {
  Box,
  Container,
  Typography,
  CssBaseline,
  Divider,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import OfferNotificationBar from "../Common/OfferNotificationBar";
import Sidebar from "./Sidebar";
import { orderDetailApi, orderInvoiceApi } from "../../features/user/userApi";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";

const OrderDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [orderData, setOrderData] = useState([]);

  const fetchData = async (id) => {
    try {
      const orders = await orderDetailApi(id);
      if (orders.status) {
        setOrderData(orders?.data);
      } else {
        toast.error(orders?.message);
      }
    } catch (error) {}
  };
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const downloadPdf = async () => {
    if (id) {
      const invoice = await orderInvoiceApi(id);
      if (invoice?.status) {
        const link = document.createElement("a");
        link.href = invoice?.pdf_url;
        link.target = '_blank';
        link.download = "invoice.pdf"; // Change the filename as needed

        // Append the link to the body
        document.body.appendChild(link);
        link.click();

        // Clean up
        link.parentNode.removeChild(link);
      }
    }
  };

  return (
    <>
      <OfferNotificationBar />
      <Header />
      <section style={{ background: "#f6faff" }}>
        <Container className="dashboard_wrap">
          <CssBaseline />
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Sidebar orderData={orderData}/>
            <Box className="dashboard_content">
              <Typography variant="h1">{t("Order Summary")}</Typography>
              {orderData?.items?.length > 0 && (
                <>
                  {orderData?.items?.map((product, index) => (
                    <Box className="order_summary_row" key={`orderproduct${index}`}>
                      <div className="order_summary_image">
                        <img
                          src={product?.product?.images?.[0]?.file_path}
                          alt={product?.product?.name}
                          width={100}
                        />
                      </div>
                      <Box className="order_summary_content">
                        <Typography variant="h6" className="os_name">{product?.product?.name}</Typography>
                        <Typography>
                          <span>{t("Strength")}:</span> {product?.strength}
                        </Typography>
                        <Typography>
                          <span>{t("Quantity in package")}:</span>{" "}
                          {product?.pack_qty}
                        </Typography>
                        <Box className="os_price">{product?.total} €</Box>
                      </Box>
                    </Box>
                  ))}
                </>
              )}
              <Box>
                <Box className="os_total">
                  <Box className="os_total_bx">
                    <Typography>
                      {t("Subtotal")}:{" "}
                      <span>{orderData?.sub_total_amount} €</span>
                    </Typography>
                    <Typography>
                      {t("Shipment")}:{" "}
                      <span>
                        {orderData?.shipping_cost > 0
                          ? orderData?.shipping_cost
                          : t("Free Shipping")}
                      </span>
                    </Typography>
                    <Typography>
                      {t("Discount")}:{" "}
                      <span>
                        {orderData?.discount_amount > 0
                          ? `- ${orderData?.discount_amount} €`
                          : `0 €`}{" "}
                        
                      </span>
                    </Typography>
                    <Typography>
                      {t("Prescription fee")}:{" "}
                      <span>{orderData?.prescription_fee} €</span>
                    </Typography>
                    <Divider />
                    <Typography>
                      {t("In total")}: <span>{orderData?.total_amount} €</span>
                    </Typography>
                  </Box>
                  <Box className="os_appendix">
                    <Typography sx={{ fontWeight: 600, color: "#0f3f83" }}>1{t("Appendix")}</Typography>
                    <Divider sx={{ mb: "1rem" }} />
                    <IconButton
                      variant="text"
                      onClick={downloadPdf}
                    >
                      <Icon icon="vscode-icons:file-type-pdf2" />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default OrderDetail;
