import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { Icon } from "@iconify/react";
import {
  Box,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Dialog,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  CircularProgress,
  Divider,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-hot-toast"; // For toast notifications
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom"; // For navigation
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const QuestionnaireModal = (props) => {
  const { open, onClose, productObject, questions } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [answers, setAnswers] = useState({});
  const [errorMessages, setErrorMessages] = useState({});
  const questionList = questions?.data;
  let additionalAnswers = 0;
  const q = questions?.data?.filter(
    (quest) => quest?.additional_text_field === 1
  );
  additionalAnswers = q?.length || 0;

  const handleAnswerChange = (questionId, value) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: value,
    }));

    const quesCheck = questionList?.find((q) => {
      console.log(q?.correct_answer, value);
      return (
        q?.question === questionId &&
        value?.toLowerCase() !== q?.correct_answer?.toLowerCase() &&
        q?.correct_answer !== "" &&
        q?.correct_answer !== null
      );
    });

    if (quesCheck || value === null) {
      setErrorMessages((prev) => ({
        ...prev,
        [questionId]: t(
          "This medicine is not suitable for you. Please consult your GP."
        ),
      }));
    } else {
      setErrorMessages((prev) => ({
        ...prev,
        [questionId]: "",
      }));
    }
  };

  const handleDropDownChange = (e, questionId) => {
    if (e?.target?.value !== "") {
      setAnswers((prev) => ({
        ...prev,
        [questionId]: e?.target?.value,
      }));
    } else {
      const ans = { ...answers };
      const newAns = Object.keys(ans)
        .filter((key) => key !== questionId)
        .reduce((obj, key) => {
          obj[key] = ans[key];
          return obj;
        }, {});
      setAnswers(newAns);
    }

    const quesCheck = questionList?.find((q) => {
      return (
        q?.question === questionId &&
        e?.target?.value?.toLowerCase() != q?.correct_answer?.toLowerCase() &&
        q?.correct_answer !== "" &&
        q?.correct_answer !== null
      );
    });

    if (quesCheck || e?.target?.value === "" || e.target.value === null) {
      setErrorMessages((prev) => ({
        ...prev,
        [questionId]: t(
          "This medicine is not suitable for you. Please consult your GP."
        ),
      }));
    } else {
      setErrorMessages((prev) => ({
        ...prev,
        [questionId]: "",
      }));
    }
  };

  const handleChange = (e, questionId) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: e.target.value,
    }));
    if (e.target.value === "") {
      setErrorMessages((prev) => ({
        ...prev,
        [questionId]: t("Please enter the answer of above question."),
      }));
      const ans = { ...answers };
      const newAns = Object.keys(ans)
        .filter((key) => key !== questionId)
        .reduce((obj, key) => {
          obj[key] = ans[key];
          return obj;
        }, {});
      setAnswers(newAns);
    } else {
      setErrorMessages((prev) => ({
        ...prev,
        [questionId]: "",
      }));
    }
  };
  const handleChangeAdditional = (e, questionId) => {
    setAnswers((prev) => ({
      ...prev,
      [`${questionId}---additional`]: e.target.value,
    }));
    if (e.target.value === "") {
      setErrorMessages((prev) => ({
        ...prev,
        [`${questionId}---additional`]: t(
          "Please enter the answer of above question."
        ),
      }));
      const ans = { ...answers };
      const newAns = Object.keys(ans)
        .filter((key) => key !== `${questionId}---additional`)
        .reduce((obj, key) => {
          obj[key] = ans[key];
          return obj;
        }, {});
      setAnswers(newAns);
    } else {
      setErrorMessages((prev) => ({
        ...prev,
        [`${questionId}---additional`]: "",
      }));
    }
  };

  const handleCheckBoxChange = (e, questionId) => {
    const allans = answers?.[questionId];
    if (e.target.checked) {
      if (allans !== undefined && allans?.length > 0) {
        if (allans?.indexOf(e.target.value) === -1) {
          setAnswers((prev) => ({
            ...prev,
            [questionId]: [...allans, e.target.value],
          }));
        } else {
          setAnswers((prev) => ({
            ...prev,
            [questionId]: [...allans, e.target.value],
          }));
        }
      } else {
        setAnswers((prev) => ({
          ...prev,
          [questionId]: [e.target.value],
        }));
      }
    } else {
      if (allans?.length > 0 && allans?.indexOf(e.target.value) > -1) {
        setAnswers((prev) => ({
          ...prev,
          [questionId]: allans.filter((a) => a !== e.target.value),
        }));
      }
    }
    const abc = allans === undefined ? 0 : allans?.length;
    const checkLength = e.target.checked ? abc + 1 : abc > 0 ? abc - 1 : 0;
    if (checkLength === 0) {
      setErrorMessages((prev) => ({
        ...prev,
        [questionId]: t("Please enter the answer of above question."),
      }));
      const ans = { ...answers };
      const newAns = Object.keys(ans)
        .filter((key) => key !== questionId)
        .reduce((obj, key) => {
          obj[key] = ans[key];
          return obj;
        }, {});
      setAnswers(newAns);
    } else {
      setErrorMessages((prev) => ({
        ...prev,
        [questionId]: "",
      }));
    }
  };

  const handleSubmit = async () => {
    if (!isSubmitDisabled) {
      const item = { ...productObject };
      item.answers = answers;
      const getCartData = window.localStorage.getItem("cartData");
      if (getCartData) {
        const cartItem = JSON.parse(getCartData);
        cartItem.push(item);
        window.localStorage.setItem("cartData", JSON.stringify(cartItem));
      } else {
        window.localStorage.setItem("cartData", JSON.stringify([item]));
      }
      window.dispatchEvent(new Event("cartUpdate"));
      window.localStorage.removeItem("addToCartProduct");

      toast.success(t("Product added to cart"));
      navigate(location.pathname, { state: { showThankYouPage: true } }); // Pass flag in navigation state
    }
  };

  const isSubmitDisabled =
    Object.keys(answers)?.length !== questionList?.length + additionalAnswers ||
    Object.values(errorMessages).some((msg) => msg !== "");

  const renderErrorMessage = (message) => (
    <Box className="error_box">
      <InfoIcon />
      <Typography variant="body2">{message}</Typography>
    </Box>
  );

  const renderOptions = (options) => {
    const opt = options?.split(",");
    return (
      <>
        {opt?.length > 0 &&
          opt?.map((option) => (
            <ToggleButton value={option?.trim()}>{option?.trim()}</ToggleButton>
          ))}
      </>
    );
  };

  const renderCheckbox = (options, questionTitle) => {
    const opt = options?.split(",");
    return (
      <>
        {opt?.length > 0 &&
          opt?.map((option) => (
            <FormControlLabel
              className="checkboxFieldClass"
              value={option}
              onChange={(e) => handleCheckBoxChange(e, questionTitle)}
              control={<Checkbox />}
              label={option}
            />
          ))}
      </>
    );
  };

  const renderDropDownOptions = (options) => {
    const opt = options?.split(", ");
    return (
      <>
        <option value="">Select Option</option>
        {opt?.length > 0 &&
          opt?.map((option) => <option value={option}>{option}</option>)}
      </>
    );
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <Grid
        className="cartFaq_wrap"
        container
        sx={{
          width: "100%",
          bgcolor: "#f6faff",
          position: "relative",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box className="cartFaq_logo">
            <a href="/">
              <img src="/images/apotheke-premium-logo.webp" width={200} height={80} alt="" />
            </a>
          </Box>
          <IconButton className="cartFaq_close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Grid className="cartFaq_form_container" container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={7}>
              <Box className="questionnaire_title">
                <Typography variant="h6">
                  <Icon icon="mage:question-mark-circle-fill" />
                  {t(
                    "To order this product you must fill out this questionnaire."
                  )}
                </Typography>
              </Box>
              <Box className="question_login">
                <Typography variant="body1">
                  {t(
                    "Have you ordered this product before? Log in and save time filling out your review."
                  )}
                </Typography>
              </Box>
              <div className="qsnans_box">
                <div className="disclaimer_box">
                  <Typography variant="h6">{t("Disclaimer")}</Typography>
                  <Typography variant="body2">
                    {t(
                      "This questionnaire forms the basis for your consultation today..."
                    )}
                  </Typography>
                </div>

                <div className="qsnans_form">
                  {questionList?.map((question, index) => (
                    <Fragment key={question?.id}>
                      <Box className="question_row">
                        {question?.answer_type === "radio" && (
                          <>
                            <div className="question_title">
                              <div className="question_number">{index + 1}</div>
                              <Box
                                className="question_text"
                                variant="body1"
                                dangerouslySetInnerHTML={{
                                  __html: question?.question,
                                }}
                              ></Box>
                            </div>
                            <ToggleButtonGroup
                              className="question_control"
                              value={answers[question?.question] || ""}
                              exclusive
                              onChange={(e, value) =>
                                handleAnswerChange(question?.question, value)
                              }
                            >
                              {question?.options !== "" &&
                                renderOptions(question?.options)}
                            </ToggleButtonGroup>
                          </>
                        )}
                        {question?.answer_type === "checkbox" && (
                          <>
                            <div className="question_title">
                              <div className="question_number">{index + 1}</div>
                              <Box
                                className="question_text"
                                variant="body1"
                                dangerouslySetInnerHTML={{
                                  __html: question?.question,
                                }}
                              ></Box>
                            </div>
                          </>
                        )}
                        {question?.answer_type === "text" && (
                          <>
                            <div className="question_title">
                              <div className="question_number">{index + 1}</div>
                              <Box
                                className="question_text"
                                variant="body1"
                                dangerouslySetInnerHTML={{
                                  __html: question?.question,
                                }}
                              ></Box>
                            </div>
                          </>
                        )}
                        {question?.answer_type === "dropdown" && (
                          <>
                            <div className="question_title">
                              <div className="question_number">{index + 1}</div>
                              <Box
                                className="question_text"
                                variant="body1"
                                dangerouslySetInnerHTML={{
                                  __html: question?.question,
                                }}
                              ></Box>
                            </div>
                          </>
                        )}
                      </Box>
                      <Box className="additionQuestions_box">
                        {question?.answer_type === "checkbox" && question?.additional_question_text !== null &&
                          question?.additional_question_text !== undefined &&
                          question?.additional_question_text !== "" && (
                            <Box
                              dangerouslySetInnerHTML={{
                                __html: question?.additional_question_text,
                              }}
                              className="additionQuestionText"
                            ></Box>
                          )}
                        <Box>
                          {question?.answer_type === "checkbox" &&
                            question?.options !== "" &&
                            renderCheckbox(question?.options, question?.question)}
                        </Box>
                      </Box>
                      {question?.answer_type === "text" && (
                        <TextField
                          value={answers[question?.question] || ""}
                          onChange={(e) => handleChange(e, question?.question)}
                        />
                      )}
                      {question?.answer_type === "dropdown" && (
                        <select
                          className="formControl"
                          value={answers[question?.question]}
                          onChange={(e) => {
                            handleDropDownChange(e, question?.question);
                          }}
                        >
                          {question?.options !== "" &&
                            renderDropDownOptions(question?.options)}
                        </select>
                      )}
                      {errorMessages[question?.question] &&
                        renderErrorMessage(errorMessages[question?.question])}

                      {question?.answer_type !== "checkbox" &&
                        question?.additional_question_text !== null &&
                        question?.additional_question_text !== undefined &&
                        question?.additional_question_text !== "" && (
                          <Box
                            dangerouslySetInnerHTML={{
                              __html: question?.additional_question_text,
                            }}
                            className="additionQuestionText"
                          ></Box>
                        )}
                      {question?.additional_text_field === 1 &&
                        question?.additional_text_field_value !== null &&
                        question?.additional_text_field_value !== undefined &&
                        question?.additional_text_field_value !== "" && (
                          <Box className="innerQsn_row">
                            <Icon icon="mage:question-mark-circle-fill" />
                            <Box
                              dangerouslySetInnerHTML={{
                                __html: question?.additional_text_field_value,
                              }}
                              className="additionQuestionTextFieldQuestion"
                            ></Box>
                          </Box>
                        )}
                      {question?.additional_text_field === 1 &&
                        answers?.[question?.question] ===
                          question?.correct_answer && (
                          <TextField
                          className="cartQsnTxtFld"
                            value={
                              answers[`${question?.question}---additional`] ||
                              ""
                            }
                            onChange={(e) =>
                              handleChangeAdditional(e, question?.question)
                            }
                          />
                        )}
                      <Divider />
                    </Fragment>
                  ))}

                  {Object.keys(answers)?.length ===
                    questionList?.length + additionalAnswers &&
                    Object.values(errorMessages).some((msg) => msg !== "") &&
                    renderErrorMessage(t("Please re-check your answer(s)."))}

                  <div className="qsnSubmit_btn">
                    <Button variant="contained" onClick={handleSubmit}>
                      {t("Submit")}
                    </Button>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
              <div className="form_progress_tracker">
                <Box className="completion_time_box">
                  <Typography variant="h6">
                    {t("Estimated completion time {{minute}} minutes", {
                      minute: 3,
                    })}
                  </Typography>
                </Box>
                <Box className="progress_bar_box">
                  <CircularProgress
                    className="progress_bar"
                    variant="determinate"
                    value={
                      (Object.keys(answers)?.length /
                        (questionList?.length + additionalAnswers)) *
                      100
                    }
                    size={250}
                    thickness={1}
                    sx={{ color: "#fbaa1a" }}
                  />
                  <Box className="progress_bar_dotted">
                    <Typography variant="h2">
                      {`${(
                        (Object.keys(answers)?.length /
                          (questionList?.length + additionalAnswers)) *
                        100
                      ).toFixed(0)}%`}
                    </Typography>
                    <Typography variant="h6">
                      {t("Completion of the assessment")}
                    </Typography>
                  </Box>
                </Box>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default QuestionnaireModal;
